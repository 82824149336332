import { Box, Portal, Text, useBreakpointValue } from "@chakra-ui/react";

const BreakpointHelper = () => {
  const breakpointText = useBreakpointValue(
    {
      base: `base`,
      sm: `sm`,
      md: `md`,
      lg: `lg`,
      xl: `xl`,
      "2xl": `2xl`,
      "3xl": `3xl`,
      "4xl": `4xl`,
      "5xl": `5xl`,
    },
    { ssr: false },
  );

  return (
    <Portal>
      <Box zIndex={10000} position="fixed" bottom="0" right="0" p={6}>
        <Text color="red" textStyle="deprecated-heading-7xl">
          {breakpointText}
        </Text>
      </Box>
    </Portal>
  );
};

export default BreakpointHelper;
