import { CaretDown } from "@phosphor-icons/react";
import { t } from "i18next";
import { sortBy } from "lodash";

import { useRouter } from "next/router";

import {
  HStack,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Circle,
  Box,
} from "@chakra-ui/react";

import { HoldingCompanyLogo } from "@/components/unaccredited-seller";
import {
  HoldingsQuery,
  ListedHoldingFragment,
  UnlistedHoldingFragment,
  Holding,
} from "@/gql";
import { useSubscribeToNotifications } from "@/hooks";
import {
  mapHoldingNotifications,
  getNotifiedHoldings,
  doesHoldingHaveNotification,
} from "@/utils";

const HoldingNotification = ({
  holding,
  onClick,
}: {
  readonly holding: Holding;
  readonly onClick: () => void;
}) => (
  <Box position="relative" borderRadius="lg" onClick={onClick} cursor="pointer">
    <HoldingCompanyLogo
      logoUrl={holding.company.logoUrl}
      name={holding.company.name}
    />
    <Circle
      bg="salmon.900"
      pos="absolute"
      size={2.5}
      top="0"
      border="solid 1px white"
      transform="translate(40%, -40%)"
      right="0"
    />
  </Box>
);

export const HoldingMenu = ({
  holdingCompany,
  holdings,
}: {
  readonly holdingCompany: ListedHoldingFragment | null;
  readonly holdings: HoldingsQuery["myHoldings"]["holdingsV2"];
}) => {
  const disclosure = useDisclosure();
  const router = useRouter();
  const { unreadNotifications: notifications } = useSubscribeToNotifications();

  const singleHolding = holdings?.length === 1;

  if (!holdings || !holdingCompany || singleHolding) return null;

  const holdingNotifications = mapHoldingNotifications({
    holdings,
    notifications,
    currentHolding: holdingCompany,
  });

  const renderMenuItem = (
    holding: ListedHoldingFragment | UnlistedHoldingFragment,
  ) => {
    const isSelected = holding.id === holdingCompany.id;

    if (isSelected) return null;

    switch (holding.__typename) {
      case `Holding`:
        return (
          <MenuItem
            key={holding.id}
            onClick={() => {
              router.push(`/dashboard/${holding.companyId}`);
            }}
          >
            <HStack>
              <HStack>
                <HoldingCompanyLogo
                  logoUrl={holding.company.logoUrl}
                  name={holding.company.name}
                />
                <Text textStyle="heading-md" noOfLines={1} maxW={32}>
                  {holding.company.name}
                </Text>
              </HStack>
              {doesHoldingHaveNotification({
                holding,
                holdingNotifications,
              }) && <Circle bg="salmon.900" size={2.5} />}
            </HStack>
          </MenuItem>
        );
      case `UnlistedHolding`:
        return (
          <MenuItem key={holding.id} isDisabled>
            <HStack w="full" justifyContent="space-between">
              <HStack spacing={3}>
                <HoldingCompanyLogo name={holding.companyName} />
                <Text textStyle="heading-md" noOfLines={1} maxW={24}>
                  {holding.companyName}
                </Text>
              </HStack>
              <Text textStyle="text-sm">{t(`unavailable`)}</Text>
            </HStack>
          </MenuItem>
        );

      default:
        return null;
    }
  };

  const notifiedHoldings = getNotifiedHoldings({
    holdings,
    holdingNotifications,
  });

  return (
    <Menu {...disclosure} placement="bottom-start">
      <HStack
        borderColor="grey.50"
        borderWidth="1px"
        borderRadius="50px"
        borderLeft="none"
      >
        <MenuButton
          as={Button}
          p={5}
          borderRadius="50px"
          _hover={{ bg: `grey.50` }}
          _active={{ bg: `grey.75` }}
          bg="white"
          borderColor="grey.100"
          transition=".2s all ease-in-out"
          borderWidth="1px"
          style={{ zIndex: 2 }}
          rightIcon={<CaretDown size={22} />}
        >
          <HStack spacing={3}>
            <HoldingCompanyLogo
              logoUrl={holdingCompany.company.logoUrl}
              name={holdingCompany.company.name}
            />
            <Text textStyle="heading-md" position="relative" top="1px">
              {holdingCompany.company.name}
            </Text>
          </HStack>
        </MenuButton>
        {notifiedHoldings.length > 0 && (
          <HStack gap={3} pr={5}>
            {notifiedHoldings.map((holding: Holding) => (
              <HoldingNotification
                key={holding.id}
                holding={holding}
                onClick={() => {
                  router.push(`/dashboard/${holding.companyId}`);
                }}
              />
            ))}
          </HStack>
        )}
      </HStack>
      <MenuList w={64}>
        {sortBy(holdings, [`__typename`]).map(renderMenuItem)}
      </MenuList>
    </Menu>
  );
};
