import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { useRouter } from "next/router";

import { Box, HStack, VStack } from "@chakra-ui/react";

import { InternalLink, Logo } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { CompaniesSearch, NotificationMenu } from "@/components/navigation";
import { UserWithInstitutionFragment } from "@/gql";
import { useCheckAuthenticationToken } from "@/hooks";
import { useHiive50Enabled, useMarketplaceLogin } from "@/hooks/featureFlags";
import { getIsBroker } from "@/utils";
import config from "@/utils/constants";

import { NavLink } from "./NavLink";
import { ProfileMenu } from "./ProfileMenu";

const DesktopNavBar = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { pathname } = useRouter();
    const { t } = useTranslation();

    const maxW = match(pathname)
      .with(`/`, () => `max-width-lg`)
      .with(`/dashboard`, () => `max-width-lg`)
      .with(`/companies/browse`, () => `max-width-xl`)
      .with(`/create-listing`, () => `max-width-lg`)
      .with(
        P.when((pathname: string) => pathname.startsWith(`/account`)),
        () => `max-width-lg`,
      )
      .with(
        P.when((pathname: string) => pathname.endsWith(`connect-account`)),
        () => `max-width-lg`,
      )
      .with(P.any, () => `max-width-md`)
      .exhaustive();

    const isHiive50Enabled = useHiive50Enabled();
    const marketplaceLoginEnabled = useMarketplaceLogin();
    const token = useCheckAuthenticationToken();

    const backofficeBaseUrl =
      config.back_office_url[process.env.NEXT_PUBLIC_INFRA_ENV || `local`];

    const backofficeUrl = marketplaceLoginEnabled
      ? `${backofficeBaseUrl}?token=${token}`
      : backofficeBaseUrl;

    return (
      <Box position="fixed" top={0} left={0} right={0} zIndex="modal">
        <VStack
          h="desktop-navbar-height"
          bg="white"
          px={8}
          borderBottom="0.5px solid"
          borderColor="grey.200"
        >
          <HStack maxW={maxW} justifyContent="space-between" h="full" w="full">
            <HStack spacing={10}>
              {getIsBroker(actor) ? (
                <InternalLink href="/dashboard" aria-label="Hiive Connect">
                  <Logo.ConnectGrey />
                </InternalLink>
              ) : (
                <InternalLink href="/dashboard" aria-label="Hiive">
                  <Logo.Grey />
                </InternalLink>
              )}
              <HStack>
                <NavLink href="/dashboard">{t(`dashboard`)}</NavLink>
                <NavLink href="/companies/browse">
                  {t(`browse_companies`)}
                </NavLink>
                {isHiive50Enabled && (
                  <NavLink href="/hiive50">{t(`hiive_50`)}</NavLink>
                )}
                {actor.isSuperadmin && (
                  <NavLink href={backofficeUrl}>{t(`admin`)}</NavLink>
                )}
              </HStack>
            </HStack>
            <HStack spacing={5}>
              <CompaniesSearch />
              <NotificationMenu key={maxW} />
              <ProfileMenu />
            </HStack>
          </HStack>
        </VStack>
      </Box>
    );
  },
);

export default DesktopNavBar;
