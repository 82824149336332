import { ReactNode } from "react";

import { LinkProps } from "@chakra-ui/react";

import { InternalLink } from "@/components/common";

interface NavMenuLinkProps extends LinkProps {
  readonly href: string;
  readonly children: ReactNode;
  readonly onClick: () => void;
}

export const NavMenuLink = ({
  href,
  children,
  onClick,
  ...linkProps
}: NavMenuLinkProps) => (
  <InternalLink
    href={href}
    onClick={onClick}
    w="full"
    px={5}
    py={2.5}
    textStyle="heading-md"
    {...linkProps}
  >
    {children}
  </InternalLink>
);
