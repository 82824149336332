import { ReactNode } from "react";

import { HStack } from "@chakra-ui/react";

export const MobileNavBarWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <HStack
    w="full"
    h="mobile-navbar-height"
    bg="white"
    px={4}
    borderBottomWidth="0.5px"
    borderColor="grey.200"
    justifyContent="space-between"
    aria-label="Mobile Navigation Menu"
  >
    {children}
  </HStack>
);
