import { Box } from "@chakra-ui/react";

const NavSpacer = () => (
  <Box
    h={{
      base: `mobile-navbar-height`,
      lg: `desktop-navbar-height`,
    }}
  />
);
export default NavSpacer;
