import { useTranslation } from "react-i18next";

import {
  Divider,
  Flex,
  HStack,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";

import { InitialsBadge } from "@/components/navigation";
import { ListedHoldingFragment } from "@/gql";
import { useCurrentActor, useSignOut } from "@/hooks";
import { constants } from "@/utils";

import { NavMenuLink } from "./NavMenuLink";

export const UnaccreditedSellerNavFooterV2 = ({
  onClick,
  holding,
}: {
  readonly onClick: () => void;
  holding: ListedHoldingFragment | null;
}) => {
  const actor = useCurrentActor();
  const signOut = useSignOut();
  const handleSignOut = () => signOut(`/login`);
  const { t } = useTranslation();

  if (!holding) return null;

  return (
    <Flex direction="column" gap={5}>
      <Divider mx={4} w="full" />
      <VStack spacing={0} w="full">
        <HStack w="full" px={5} py={2.5}>
          <InitialsBadge />
          <VStack spacing={0} alignItems="flex-start">
            <Text textStyle="heading-md">{actor.name}</Text>
            <Text textStyle="text-xs">{actor.email}</Text>
          </VStack>
        </HStack>
        <NavMenuLink onClick={onClick} href="/account">
          {t(`account`)}
        </NavMenuLink>
        <NavMenuLink
          onClick={() => {}}
          href={`mailto:${constants.email_support}?subject=${t(
            `contact_hiive`,
          )}`}
        >
          {t(`help`)}
        </NavMenuLink>
        <ChakraLink
          onClick={handleSignOut}
          w="full"
          px={5}
          py={2.5}
          textStyle="heading-md"
        >
          {t(`sign_out`)}
        </ChakraLink>
      </VStack>
    </Flex>
  );
};
