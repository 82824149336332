import { ReactNode } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Link as ChakraLink } from "@chakra-ui/react";

interface NavLinkProps {
  readonly href: string;
  readonly children: ReactNode;
}

export const NavLink = ({ href, children }: NavLinkProps) => {
  const router = useRouter();
  const isIndexLink = [`/`, `/dashboard`].includes(href);
  const isActive = isIndexLink
    ? router.pathname === href
    : router.pathname.includes(href);
  return (
    <Link legacyBehavior href={href} passHref>
      <ChakraLink
        px={5}
        py={2}
        borderRadius="lg"
        _hover={{ bg: `grey.50` }}
        _active={{ bg: `grey.75` }}
        bg={isActive ? `grey.50` : `white`}
        borderColor={isActive ? `grey.100` : `white`}
        transition=".2s all ease-in-out"
        borderWidth="1px"
        textStyle="heading-md"
        textAlign="center"
      >
        {children}
      </ChakraLink>
    </Link>
  );
};
