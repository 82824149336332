import { useTranslation } from "react-i18next";

import { HStack, Text, VStack } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { UnaccreditedDashboardRoutesV2 } from "@/components/dashboard";
import {
  UnaccreditedSellerInlineNotificationsProvider,
  UnaccreditedSellerActiveBidNotificationsCountV2,
  UnaccreditedSellerInquiryNotificationsCountV2,
  UnaccreditedSellerPastBidNotificationsCountV2,
  UnaccreditedSellerStandingBidNotificationsCountV2,
  UnaccreditedSellerTransactionNotificationsCountV2,
} from "@/components/unaccredited-seller";
import { useUnaccreditedSellerNavbarByIdActivityQuery } from "@/gql";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2 } from "@/utils";

import { NavMenuLink } from "./NavMenuLink";

export const UnaccreditedSellerNavMenuV2 = ({
  id,
  onClick,
}: {
  readonly onClick: () => void;
  readonly id: string;
}) => {
  const query = useUnaccreditedSellerNavbarByIdActivityQuery({
    variables: { id },
  });
  const { t } = useTranslation();

  return (
    <UnaccreditedSellerInlineNotificationsProvider>
      <WithQuery query={query}>
        {({ data: { companyById: company } }) => {
          const { activity } = company;
          const listing = activity.myListings[0] ?? null;

          if (
            !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2({
              activity,
            }) &&
            !listing
          ) {
            return null;
          }

          return (
            <VStack spacing={0} alignItems="flex-start" w="full">
              {!!listing && (
                <NavMenuLink
                  onClick={onClick}
                  href={`/dashboard/${id}${UnaccreditedDashboardRoutesV2.ActiveBids}`}
                >
                  <HStack justifyContent="space-between">
                    <Text>{t(`active_bids`)}</Text>
                    <UnaccreditedSellerActiveBidNotificationsCountV2 id={id} />
                  </HStack>
                </NavMenuLink>
              )}
              {!!listing && (
                <NavMenuLink
                  onClick={onClick}
                  href={`/dashboard/${id}${UnaccreditedDashboardRoutesV2.Inquiries}`}
                >
                  <HStack justifyContent="space-between">
                    <Text>{t(`inquiries`)}</Text>
                    <UnaccreditedSellerInquiryNotificationsCountV2
                      listingId={listing.id}
                    />
                  </HStack>
                </NavMenuLink>
              )}
              <NavMenuLink
                onClick={onClick}
                href={`/dashboard/${id}${UnaccreditedDashboardRoutesV2.StandingBids}`}
              >
                <HStack justifyContent="space-between">
                  <Text>
                    {company.name} {t(`standing_bids`)}
                  </Text>
                  <UnaccreditedSellerStandingBidNotificationsCountV2 id={id} />
                </HStack>
              </NavMenuLink>
              <NavMenuLink
                onClick={onClick}
                href={`/dashboard/${id}${UnaccreditedDashboardRoutesV2.Transactions}`}
              >
                <HStack justifyContent="space-between">
                  <Text>{t(`transactions`)}</Text>
                  <UnaccreditedSellerTransactionNotificationsCountV2 id={id} />
                </HStack>
              </NavMenuLink>
              {!!listing && (
                <NavMenuLink
                  onClick={onClick}
                  href={`/dashboard/${id}${UnaccreditedDashboardRoutesV2.PastBids}`}
                >
                  <HStack justifyContent="space-between">
                    <Text>{t(`past_bids`)}</Text>
                    <UnaccreditedSellerPastBidNotificationsCountV2 id={id} />
                  </HStack>
                </NavMenuLink>
              )}
            </VStack>
          );
        }}
      </WithQuery>
    </UnaccreditedSellerInlineNotificationsProvider>
  );
};
