import { useTranslation } from "react-i18next";

import NextImage from "next/image";
import { useRouter } from "next/router";

import { HStack, Text } from "@chakra-ui/react";

import { Skeleton, WithQuery } from "@/components/common";
import { useUnaccreditedSellerNavbarMyActivityQuery } from "@/gql";

import { UnaccreditedSellerNavLink } from "./DesktopNavBar";

const MyCompanyLinkSkeleton = () => <Skeleton w="196px" h="41px" />;

const MyCompanyLink = () => {
  const query = useUnaccreditedSellerNavbarMyActivityQuery();
  const { pathname } = useRouter();
  const { t } = useTranslation();

  return (
    <WithQuery query={query} fallback={<MyCompanyLinkSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: {
            myCompany: { id, logoUrl, name },
          },
        },
      }) => (
        <UnaccreditedSellerNavLink
          href={`/companies/${id}`}
          isActive={pathname === `/my-company`}
          dataTestId="my-company-link"
        >
          <HStack>
            {!!logoUrl && (
              <NextImage
                style={{ maxHeight: 24, maxWidth: 24, objectFit: `contain` }}
                alt={name}
                height={24}
                src={logoUrl}
                width={24}
              />
            )}
            <Text>{t(`company_overview`, { companyName: name })}</Text>
          </HStack>
        </UnaccreditedSellerNavLink>
      )}
    </WithQuery>
  );
};

export default MyCompanyLink;
