import { Show } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import { getIsUnaccreditedSeller } from "@/utils";

import {
  DesktopNavBar,
  UnaccreditedSellerDesktopNavBar,
  UnaccreditedSellerDesktopNavBarV2,
} from "./DesktopNavBar";
import { MobileNavBar } from "./MobileNavBar";
import UnaccreditedSellerMobileNavBar from "./MobileNavBar/UnaccreditedSellerMobileNavBar";
import { HoldingProvider } from "./UnaccreditedSellerHoldingsProvider";

interface NavBarProps {
  readonly actor: UserWithInstitutionFragment;
}

const NavBar = ({ actor }: NavBarProps) => {
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const multipleHoldingsEnabled = useMultipleHoldings();

  const renderDesktopNavBar = () => {
    if (isUnaccreditedSeller) {
      return multipleHoldingsEnabled ? (
        <HoldingProvider>
          <UnaccreditedSellerDesktopNavBarV2 />
        </HoldingProvider>
      ) : (
        <UnaccreditedSellerDesktopNavBar />
      );
    }
    return <DesktopNavBar />;
  };

  const renderMobileNavBar = () => {
    if (isUnaccreditedSeller && multipleHoldingsEnabled) {
      return (
        <HoldingProvider>
          <UnaccreditedSellerMobileNavBar />
        </HoldingProvider>
      );
    }
    return <MobileNavBar />;
  };

  return (
    <>
      <Show above="lg" ssr={false}>
        {renderDesktopNavBar()}
      </Show>
      <Show below="lg" ssr={false}>
        {renderMobileNavBar()}
      </Show>
    </>
  );
};

export default withCurrentActor(NavBar);
