import {
  NotificationCell,
  NotificationFeedPopover,
  NotificationIconButton,
} from "@knocklabs/react-notification-feed";
import "@knocklabs/react-notification-feed/dist/index.css";
import { useRef } from "react";

import { Center, useDisclosure } from "@chakra-ui/react";

const NotificationMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const buttonRef = useRef(null);
  /**
   * We are temporarily using the default Notification pop-up provided by Knock
   * We will implement the new design later
   */
  return (
    <Center
      h={10}
      w={10}
      minW={10}
      borderRadius="100%"
      borderWidth="1px"
      borderColor="grey.100"
      _hover={{
        bg: `grey.50`,
      }}
      transition=".15s background-color ease-in-out"
      ref={buttonRef}
    >
      <div className="rnf-feed-provider">
        <NotificationIconButton onClick={onOpen} />
        <NotificationFeedPopover
          buttonRef={buttonRef}
          isVisible={isOpen}
          onClose={onClose}
          renderItem={({ item, ...props }) => (
            <NotificationCell
              key={item.id}
              {...props}
              item={item}
              avatar={null}
            />
          )}
        />
      </div>
    </Center>
  );
};
export default NotificationMenu;
