import { useTranslation } from "react-i18next";

import NextImage from "next/image";
import { useRouter } from "next/router";

import { HStack, Text } from "@chakra-ui/react";

import { ListedHoldingFragment } from "@/gql";

import { UnaccreditedSellerNavLink } from "./DesktopNavBar";

const MyCompanyLinkV2 = ({
  holding,
}: {
  holding: ListedHoldingFragment | null;
}) => {
  const { pathname } = useRouter();
  const { t } = useTranslation();

  if (!holding) return null;

  const {
    company: { id, logoUrl, name },
  } = holding;

  return (
    <UnaccreditedSellerNavLink
      href={`/companies/${id}`}
      isActive={pathname.includes(`/companies/`)}
    >
      <HStack>
        {!!logoUrl && (
          <NextImage
            style={{ maxHeight: 24, maxWidth: 24, objectFit: `contain` }}
            alt={name}
            height={24}
            src={logoUrl}
            width={24}
          />
        )}
        <Text>{t(`company_overview`, { companyName: name })}</Text>
      </HStack>
    </UnaccreditedSellerNavLink>
  );
};

export default MyCompanyLinkV2;
