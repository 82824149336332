import { X } from "@phosphor-icons/react";

import { Box, useToken } from "@chakra-ui/react";

export const XButton = ({ onClick }: { readonly onClick: () => void }) => {
  const [grey900] = useToken(`colors`, [`grey.900`]);

  return (
    <Box as="button" onClick={onClick}>
      <X size={36} color={grey900} />
    </Box>
  );
};
