import { Center, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { getUserInitials } from "@/utils";

const InitialsBadge = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const initials = getUserInitials(actor);

    return (
      <Center bg="grey.900" h={10} w={10} borderRadius="100%">
        <Text
          color="white"
          textStyle="heading-md"
          translateY="1px"
          transform="auto"
          textTransform="uppercase"
        >
          {initials}
        </Text>
      </Center>
    );
  },
);

export default InitialsBadge;
