import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { useHiive50Enabled } from "@/hooks/featureFlags";

import { NavMenuLink } from "./NavMenuLink";

export const AccreditedNavMenu = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => {
  const { t } = useTranslation();
  const isHiive50Enabled = useHiive50Enabled();

  return (
    <VStack spacing={0} w="full">
      <NavMenuLink onClick={onClick} href="/dashboard">
        {t(`dashboard`)}
      </NavMenuLink>
      <NavMenuLink onClick={onClick} href="/companies/browse">
        {t(`browse_companies`)}
      </NavMenuLink>
      {isHiive50Enabled && (
        <NavMenuLink onClick={onClick} href="/hiive50">
          {t(`hiive_50`)}
        </NavMenuLink>
      )}
    </VStack>
  );
};
