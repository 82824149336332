import { t } from "i18next";

import { HStack, Link as ChakraLink, Text, VStack } from "@chakra-ui/react";

import { InitialsBadge } from "@/components/navigation";
import { useCurrentActor, useSignOut } from "@/hooks";
import { constants } from "@/utils";

import { NavMenuLink } from "./NavMenuLink";

export const MobileNavBarFooter = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => {
  const actor = useCurrentActor();
  const signOut = useSignOut();
  const handleSignOut = () => signOut(`/login`);

  return (
    <VStack spacing={0} w="full">
      <HStack w="full" px={5} py={2.5}>
        <InitialsBadge />
        <VStack spacing={0} alignItems="flex-start">
          <Text textStyle="heading-md">{actor.name}</Text>
          <Text textStyle="text-xs">{actor.email}</Text>
        </VStack>
      </HStack>
      <NavMenuLink onClick={onClick} href="/account">
        {t(`account`)}
      </NavMenuLink>
      <NavMenuLink
        onClick={onClick}
        href={`mailto:${constants.email_support}?subject=${t(`contact_hiive`)}`}
      >
        {t(`help`)}
      </NavMenuLink>
      <ChakraLink
        onClick={handleSignOut}
        w="full"
        px={5}
        py={2.5}
        textStyle="heading-md"
      >
        {t(`sign_out`)}
      </ChakraLink>
    </VStack>
  );
};
