import { useEffect } from "react";

import { Box } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { InvestorType, UserWithInstitutionFragment } from "@/gql";
import { useLockBodyScroll, useTabRouter } from "@/hooks";
import { hasInvestorType } from "@/utils";

import { IndexTab } from "./IndexTab";
import { NavigationTab } from "./NavigationTab";
import { SearchTab } from "./SearchTab";
import { tabKeys, TabKeys } from "./tabs";

const MobileNavBar = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const tabRouter = useTabRouter<TabKeys>({
      initialTab: tabKeys.index,
    });

    const { onLock, onUnlock } = useLockBodyScroll();

    const isSeller = hasInvestorType(actor, InvestorType.Seller);
    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isBroker = hasInvestorType(actor, InvestorType.Broker);

    const { tabsInfo } = tabRouter;

    useEffect(() => {
      if (tabsInfo.currentTabKey === tabKeys.navigation) {
        onLock();
      }

      onUnlock();
    }, [tabsInfo.currentTabKey]);

    return (
      <Box position="fixed" top={0} left={0} right={0} zIndex="modal">
        {tabsInfo.currentTabKey === tabKeys.index && (
          <IndexTab actor={actor} tabRouter={tabRouter} />
        )}
        {tabsInfo.currentTabKey === tabKeys.search &&
          (isSeller || isTrader || isBroker) && (
            <SearchTab tabRouter={tabRouter} />
          )}
        {tabsInfo.currentTabKey === tabKeys.navigation && (
          <NavigationTab tabRouter={tabRouter} />
        )}
      </Box>
    );
  },
);

export default MobileNavBar;
