import { ReactNode } from "react";

import { InternalLink } from "@/components/common";

interface NavLinkProps {
  readonly href: string;
  readonly children: ReactNode;
  readonly isActive: boolean;
  readonly dataTestId?: string;
}

const UnaccreditedSellerNavLink = ({
  href,
  children,
  isActive,
  dataTestId,
}: NavLinkProps) => (
  <InternalLink
    href={href}
    px={5}
    py={2}
    borderRadius="lg"
    _hover={{ bg: `grey.50` }}
    _active={{ bg: `grey.75` }}
    bg="white"
    borderColor={isActive ? `grey.100` : `white`}
    transition=".2s all ease-in-out"
    borderWidth="1px"
    textStyle="heading-md"
    textAlign="center"
    data-testid={dataTestId}
  >
    {children}
  </InternalLink>
);

export default UnaccreditedSellerNavLink;
